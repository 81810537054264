<template>
    <!-- HEADER
	============================================= -->
    <header id="header" class="tra-menu navbar-dark light-hero-header white-scroll">
        <div class="header-wrapper">


            <!-- MOBILE HEADER -->
            <div class="wsmobileheader clearfix">	  	
                <span class="smllogo"><img src="@/assets/images/icon-logo.png" alt="mobile-logo"></span>
                <a id="wsnavtoggle" class="wsanimated-arrow"><span></span></a>	
            </div>


            <!-- NAVIGATION MENU -->
            <div class="wsmainfull menu clearfix">
                <div class="wsmainwp clearfix">


                    <!-- HEADER BLACK LOGO -->
                    <div class="desktoplogo">
                        <router-link to="/" class="logo-black"><img src="@/assets/images/icon-logo.png" alt="logo"></router-link>
                    </div>


                    <!-- HEADER WHITE LOGO -->
                    <div class="desktoplogo">
                        <router-link to="/" class="logo-white"><img src="@/assets/images/icon-footer.png" alt="logo"></router-link>
                    </div>


                    <!-- MAIN MENU -->
                    <nav class="wsmenu clearfix">
                        <ul class="wsmenu-list nav-theme">

                            <!-- DROPDOWN SUB MENU -->
                            <li aria-haspopup="true" class="d-none">
                                <a href="#" class="h-link">About <span class="wsarrow"></span></a>
                                <ul class="sub-menu">
                                    <li aria-haspopup="true"><a href="#features-2">Why Martex?</a></li>
                                    <li aria-haspopup="true"><a href="#lnk-1">Best Solutions</a></li>
                                    <li aria-haspopup="true"><a href="#lnk-2">How It Works</a></li>
                                    <li aria-haspopup="true"><a href="#lnk-3">Integrations</a></li>
                                    <li aria-haspopup="true"><a href="#reviews-2">Testimonials</a></li>	
                                </ul>
                            </li>

                            <!-- MEGAMENU -->
                            <li aria-haspopup="true" class="mg_link d-none">
                                <a href="#" class="h-link">Pages <span class="wsarrow"></span></a>
                                <div class="wsmegamenu w-75 clearfix">
                                    <div class="container">
                                        <div class="row">

                                            <!-- MEGAMENU LINKS -->
                                            <ul class="col-md-12 col-lg-3 link-list">
                                                <li class="fst-li"><a href="about.html">About Us</a></li> 
                                                <li><a href="team.html">Our Team</a></li>
                                                <li><a href="careers.html">Careers <span class="sm-info">4</span></a></li>
                                                <li><a href="career-role.html">Career Details</a></li>
                                                <li><a href="contacts.html">Contact Us</a></li>			     
                                            </ul>

                                            <!-- MEGAMENU LINKS -->
                                            <ul class="col-md-12 col-lg-3 link-list">
                                                <li><a href="features.html">Core Features</a></li>
                                                <li class="fst-li"><a href="projects.html">Our Projects</a></li>
                                                <li><a href="project-details.html">Project Details</a></li> 
                                                <li><a href="reviews.html">Testimonials</a></li>	
                                                <li><a href="download.html">Download Page</a></li>           
                                            </ul>

                                            <!-- MEGAMENU LINKS -->
                                            <ul class="col-md-12 col-lg-3 link-list">
                                                <li class="fst-li"><a href="pricing-1.html">Pricing Page #1</a></li> 
                                                <li><a href="pricing-1.html">Pricing Page #2</a></li>
                                                <li><a href="faqs.html">FAQs Page</a></li>
                                                <li><a href="help-center.html">Help Center</a></li>
                                                <li><a href="404.html">404 Page</a></li>		           
                                            </ul>

                                            <!-- MEGAMENU LINKS -->
                                            <ul class="col-md-12 col-lg-3 link-list">
                                                <li class="fst-li"><a href="blog-listing.html">Blog Listing</a></li>
                                                <li><a href="single-post.html">Single Blog Post</a></li>
                                                <li><a href="login-2.html">Login Page</a></li>
                                                <li><a href="signup-2.html">Signup Page</a></li>
                                                <li><a href="reset-password.html">Reset Password</a></li>				     
                                            </ul>
        
                                        </div>  <!-- End row -->	
                                    </div>  <!-- End container -->	
                                </div>  <!-- End wsmegamenu -->	
                            </li>	<!-- END MEGAMENU -->

                            <li class="nl-simple" aria-haspopup="true">
                                <a href="/#services" class="h-link">Our Services</a>
                            </li>

                            <li class="nl-simple" aria-haspopup="true">
                                <a href="/#about-us" class="h-link">About US</a>
                            </li>

                            <li class="nl-simple" aria-haspopup="true">
                                <a href="/#our-project" class="h-link">Our Achievements</a>
                            </li>

                            <li class="nl-simple" aria-haspopup="true">
                                <a href="/#faq" class="h-link">FAQ</a>
                            </li>

                            <li class="nl-simple reg-fst-link mobile-last-link" aria-haspopup="true">
                                <router-link to="/contact" class="btn r-04 btn--theme hover--tra-black last-link">Contact us</router-link>
                            </li> 


                        </ul>
                    </nav>	<!-- END MAIN MENU -->


                </div>
            </div>	<!-- END NAVIGATION MENU -->


        </div>     <!-- End header-wrapper -->
    </header>	<!-- END HEADER -->
</template>

<script>
export default {
    name: "header"
}
</script>
