<template>
    <Header />
  <!-- <nav> 
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->

  <router-view/>
  <Footer />
</template>

<script>
import Header from '@/views/partials/header.vue'
import Footer from '@/views/partials/footer.vue'

export default {
    name: 'HomeView',
	components: {
		Header, Footer
	}
}
</script>
