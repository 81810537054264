<template>
    <!-- FOOTER-3 -->
<footer id="footer-3" class="pt-100 footer ft-3-ntr">
    <div class="container">


        <!-- FOOTER CONTENT -->
        <div class="row">


            <!-- FOOTER LOGO -->
            <div class="col-xl-3">
                <div class="footer-info">
                    <img class="footer-logo" src="@/assets/images/icon-footer.png" alt="footer-logo">
                    <!-- <p>SynCode is an Algerian IT development company which supports companies in developing their projects.</p> -->
                </div>	
            </div>	


            <!-- FOOTER LINKS -->
            <div class="col-sm-4 col-lg-3 col-xl-3">
                <div class="footer-links fl-1">
                
                    <!-- Title -->
                    <h6 class="s-17 w-700">Company</h6>

                    <!-- Links -->
                    <ul class="foo-links clearfix">
                        <li><p><a href="#about-us">About Us</a></p></li>
                        <li><p><a href="#services">Our Services</a></p></li>
                        <li><p><a href="#our-project">Our Achievements</a></p></li>	
                        <li><p><a href="#faq">FAQ</a></p></li>
                    </ul>

                </div>
            </div>	<!-- END FOOTER LINKS -->	


            <!-- FOOTER LINKS -->
            <div class="col-sm-4 col-lg-3">
                <div class="footer-links fl-2">
                                    
                    <!-- Title -->
                    <h6 class="s-17 w-700">Product</h6>

                    <!-- Links -->
                    <ul class="foo-links clearfix">
                        <li><p><a href="javascript:void(0)">Website</a></p></li>
                        <li><p><a href="javascript:void(0)">Apps Mobiles</a></p></li>	
                        <li><p><a href="javascript:void(0)">Estimation & Consultancy</a></p></li>	
                        <li><p><a href="javascript:void(0)">Payment and delivery Integration</a></p></li>	
                    </ul>

                </div>	
            </div>	<!-- END FOOTER LINKS -->	


            <!-- FOOTER LINKS -->
            <div class="col-sm-4 col-lg-3 col-xl-3  d-none">
                <div class="footer-links fl-3">
                                    
                    <!-- Title -->
                    <h6 class="s-17 w-700">Legal</h6>

                    <!-- Links -->
                    <ul class="foo-links clearfix">
                        <li><p><a href="terms.html">Terms of Use</a></p></li>										
                        <li><p><a href="privacy.html">Privacy Policy</a></p></li>
                        <li><p><a href="cookies.html">Cookie Policy</a></p></li>
                        <li><p><a href="#">Site Map</a></p></li>
                    </ul>

                </div>	
            </div>	<!-- END FOOTER LINKS -->	


            <!-- FOOTER NEWSLETTER FORM -->
            <div class="col-sm-10 col-md-8 col-lg-4 col-xl-3">
                <div class="footer-form">

                    <!-- Title -->
                    <h6 class="s-17 w-700">Contactez-nous</h6>

                    <!-- Links -->
                    <ul class="foo-links clearfix">
                        <li><p><a href="mailto:contact@syncode.com">syncodecontact@gmail.com</a></p></li>										
                        <li><p><a href="tel:+213675141568">(+213) 0675 14 15 68</a></p></li>
                        <li><p><a href="tel:+213540182968">(+213) 0540 18 29 68</a></p></li>
                    </ul>
                                            
                </div>	
            </div>	<!-- END FOOTER NEWSLETTER FORM -->


        </div>	<!-- END FOOTER CONTENT -->


        <hr>	<!-- FOOTER DIVIDER LINE -->


        <!-- BOTTOM FOOTER -->
        <div class="bottom-footer">
            <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">


                <!-- FOOTER COPYRIGHT -->
                <div class="col">
                    <div class="footer-copyright">
                        <p class="p-sm">Copyright &copy; 2024 <a href="#">SynCode.</a> All Rights Reserved.</p>
                    </div>
                </div>


                <!-- FOOTER SOCIALS -->
                <div class="col">
                    <ul class="bottom-footer-socials ico-20 text-end">
                        <li><a href="#"><span class="flaticon-facebook"></span></a></li>
                        <li><a href="#"><span class="flaticon-twitter"></span></a></li>
                        <li><a href="#"><span class="flaticon-instagram"></span></a></li>
                        <li><a href="#"><span class="flaticon-youtube"></span></a></li>
                    </ul>
                </div>


            </div>  <!-- End row -->
        </div>	<!-- END BOTTOM FOOTER -->


    </div>     <!-- End container -->	
</footer>   <!-- END FOOTER-3 -->	

</template>

<script>
export default {
    name: "footer"
}
</script>
