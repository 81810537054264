<template>
  <div class="home">
			<!-- HERO-15
			============================================= -->	
			<section id="hero-15" class="bg--scroll hero-section">
				<div class="container">	
					<div class="row d-flex align-items-center" style="padding-bottom: 60px;">


						<!-- HERO TEXT -->
						<div class="col-md-6 mb-4">
							<div class="hero-15-txt wow fadeInRight">

								<!-- Title -->
								<h2 class="s-64 w-700">Start with a plan and end with results</h2>

								<!-- Text -->
								<p class="p-lg">We are developing more ordinary businesses into brands through our competitive website designing, development & branding services.</p>

								<!-- Button -->	
								<a href="#banner-7" class="btn r-04 btn--theme hover--tra-black">Contact Us</a>
								

							</div>
						</div>	<!-- END HERO TEXT -->	


						<!-- HERO IMAGE -->
						<div class="col-md-6">	
							<div class="hero-15-img text-center wow fadeInLeft">	
								<!-- <img class="img-fluid" src="@/assets/images/hero.png"> -->
								<img class="img-fluid" src="@/assets/images/hero2.svg">
							</div>
						</div>	
						

					</div>    <!-- End row --> 	
				</div>	   <!-- End container --> 	
			</section>	<!-- END HERO-15 -->






			<!-- FEATURES-2
			============================================= -->
			<section id="services" class="py-100 features-section division">
				<div class="container">


					<!-- SECTION TITLE -->	
					<div class="row justify-content-center">	
						<div class="col-md-10 col-lg-9">
							<div class="section-title mb-80">	

								<!-- Title -->	
								<h2 class="s-50 w-700">Everything in One Place</h2>	

								<!-- Text -->	
								<p class="s-21 color--grey">Bolster Your Business Boundaries With Syncode,<br> A team of digital experts at the service of your performance</p>
									
							</div>	
						</div>
					</div>


					<!-- FEATURES-2 WRAPPER -->
					<div class="fbox-wrapper text-center">
						<div class="row row-cols-1 row-cols-md-3 rows-2">


							<!-- FEATURE BOX #1 -->
		 					<div class="col">
		 						<div class="fbox-2 fb-1 wow fadeInUp">

		 							<!-- Image -->
									<div class="fbox-img gr--whitesmoke h-170">
										<img class="img-fluid" src="@/assets/images/serv-we.svg" alt="feature-image">
									</div>

									<!-- Text -->
									<div class="fbox-txt">
										<h6 class="s-22 w-700">Web apllication</h6>
										<!-- <p>We create tailor-made websites that adapt to your needs and the objectives of your business.</p> -->
										<p>We create custom websites tailored to your needs and business objectives.</p>
									</div>

		 						</div>
		 					</div>	<!-- END FEATURE BOX #1 -->	


		 					<!-- FEATURE BOX #2 -->
		 					<div class="col">
		 						<div class="fbox-2 fb-2 wow fadeInUp">

		 							<!-- Image -->
									<div class="fbox-img gr--whitesmoke h-170">
										<img class="img-fluid" src="@/assets/images/serv-mobile2.png" alt="feature-image">
									</div>

									<!-- Text -->
									<div class="fbox-txt">
										<h6 class="s-22 w-700">Mobile apllication</h6>
										<p>The entirety of a mobile app's needs: design, development, user interface, and maintenance.</p>
									</div>

		 						</div>
		 					</div>	<!-- END FEATURE BOX #2 -->		


		 					<!-- FEATURE BOX #3 -->
		 					<div class="col">
		 						<div class="fbox-2 fb-3 wow fadeInUp">

		 							<!-- Image -->
									<div class="fbox-img gr--whitesmoke h-170">
										<img class="img-fluid" src="@/assets/images/serv-wordpress.png" alt="feature-image">
									</div>

									<!-- Text -->
									<div class="fbox-txt">
										<h6 class="s-22 w-700">Wordpress</h6>
										<p>Expert in custom WordPress themes, plugins, and multi-language sites.</p>
									</div>

		 						</div>
		 					</div>	<!-- END FEATURE BOX #3 -->	


		 					<!-- FEATURE BOX #4 -->
		 					<div class="col">
		 						<div class="fbox-2 fb-4 wow fadeInUp">

		 							<!-- Image -->
									<div class="fbox-img gr--whitesmoke h-170">
										<img class="img-fluid" src="@/assets/images/serv-payment.png" alt="feature-image">
									</div>

									<!-- Text -->
									<div class="fbox-txt">
										<h6 class="s-22 w-700">Payment and delivery Integration</h6>
										<p>Seamless and secure payment gateway solutions for all platforms.</p>
									</div>

		 						</div>
		 					</div>	<!-- END FEATURE BOX #4 -->	


		 					<!-- FEATURE BOX #5 -->
		 					<div class="col">
		 						<div class="fbox-2 fb-5 wow fadeInUp">

		 							<!-- Image -->
									<div class="fbox-img gr--whitesmoke h-170">
										<img class="img-fluid" src="@/assets/images/f_03.png" alt="feature-image">
									</div>

									<!-- Text -->
									<div class="fbox-txt">
										<h6 class="s-22 w-700">Hosting and SEO</h6>
										<p>Scalable, reliable, and fast hosting with domain management services and search engine rankings.</p>
									</div>

		 						</div>
		 					</div>	<!-- END FEATURE BOX #5 -->

							<!-- FEATURE BOX #6 -->
							<div class="col">
		 						<div class="fbox-2 fb-6 wow fadeInUp">

		 							<!-- Image -->
									<div class="fbox-img gr--whitesmoke h-170">
										<img class="img-fluid" src="@/assets/images/serv-cost.png" alt="feature-image">
									</div>

									<!-- Text -->
									<div class="fbox-txt">
										<h6 class="s-22 w-700">Project Estimation & Consultancy</h6>
										<p>Accurate project cost, timeline, and strategy planning.</p>
									</div>

		 						</div>
		 					</div>	<!-- END FEATURE BOX #6 -->	


							<!-- FEATURE BOX #5 -->
							<div class="col">
		 						<div class="fbox-2 fb-5 wow fadeInUp">

		 							<!-- Image -->
									<div class="fbox-img gr--whitesmoke h-170">
										<img class="img-fluid" src="@/assets/images/serv-uiux1.png" alt="feature-image">
									</div>

									<!-- Text -->
									<div class="fbox-txt">
										<h6 class="s-22 w-700">Design & User Experience</h6>
										<p>intuitive interfaces to enhance user engagement.</p>
									</div>

		 						</div>
		 					</div>	<!-- END FEATURE BOX #5 -->			 					

						</div>  <!-- End row -->  
					</div>	<!-- END FEATURES-2 WRAPPER -->


				</div>     <!-- End container -->
			</section>	<!-- END FEATURES-2 -->





			<!-- TEXT CONTENT
			============================================= -->
			<section id="about-us" class="pt-100 ct-02 content-section division">
				<div class="container">


					<!-- SECTION CONTENT (ROW) -->	
					<div class="row d-flex align-items-center">


						<!-- IMAGE BLOCK -->
						<div class="col-md-6">
							<div class="img-block left-column wow fadeInRight">
								<img class="img-fluid" src="@/assets/images/about2.png" alt="content-image">
							</div>
						</div>


						<!-- TEXT BLOCK -->	
						<div class="col-md-6">
							<div class="txt-block right-column wow fadeInLeft">

								<!-- Section ID -->	
						 		<span class="section-id">About Syncode</span>
	
								<!-- Title -->	
								<h2 class="s-46 w-700">The trusted partner for your digital transformation</h2>

								<!-- Text -->	
								<p>Syncode is the top IT Company offering web design, web and mobile development, and Integration services. Due to highly respective and credible core values, our company ranks in the list of top IT companies.</p>

								<!-- Small Title -->	
								<h5 class="s-24 w-700">Our Approach</h5>

								<!-- CONTENT BOX #1 -->
								<div class="cbox-1 ico-15">

		 							<div class="ico-wrap color--theme">
		 								<div class="cbox-1-ico"><span class="flaticon-check"></span></div>
		 							</div>

									<div class="cbox-1-txt">
										<p>We ensure our solutions align with your business objectives.</p>
									</div>

								</div>

								<!-- CONTENT BOX #2 -->
								<div class="cbox-1 ico-15">

		 							<div class="ico-wrap color--theme">
		 								<div class="cbox-1-ico"><span class="flaticon-check"></span></div>
		 							</div>

									<div class="cbox-1-txt">
										<p>Solutions designed for growth and long-term efficiency.</p>
									</div>

								</div>

								<!-- CONTENT BOX #3 -->
								<div class="cbox-1 ico-15">

		 							<div class="ico-wrap color--theme">
		 								<div class="cbox-1-ico"><span class="flaticon-check"></span></div>
		 							</div>

									<div class="cbox-1-txt">
										<p class="mb-0">Reliable post-launch support and maintenance services</p>
									</div>

								</div>

							</div>
						</div>	<!-- END TEXT BLOCK -->	


					</div>	<!-- END SECTION CONTENT (ROW) -->	


				</div>	   <!-- End container -->
			</section>	<!-- END TEXT CONTENT -->




			<!-- TEXT CONTENT
			============================================= -->
			<section class="py-100 ct-01 content-section division">
				<div class="container">


					<!-- SECTION CONTENT (ROW) -->	
					<div class="row d-flex align-items-center">


						<!-- TEXT BLOCK -->	
						<div class="col-md-6 order-last order-md-2">
							<div class="txt-block left-column wow fadeInRight">

								<!-- Section ID -->	
						 		<span class="section-id">TECHNOLOGIES BUNDLE</span>

								<!-- Title -->	
								<h2 class="s-46 w-700">Syncode Technologies Insight</h2>

								<!-- List -->	
								<ul class="simple-list">

									<li class="list-item">
										<p>Syncode website developers are working on the finest prototyping to extract fantastic momentum.</p>
									</li>

									<li class="list-item">
										<p>WordPress is the major CMS platform for business web design and eCommerce and development solutions that intelligently grow your business </p>
									</li>

									<li class="list-item">
										<p>Laravel, Python, and Node JS development solutions Syncode Creative Web Development Company are cutting-edge for your business idea.</p>
									</li>

									<li class="list-item">
										<p class="mb-0">handle mobile application development services on leading technology platforms such as iOS, Android, Flutter.</p>
									</li>

								</ul>

							</div>
						</div>	<!-- END TEXT BLOCK -->	


						<!-- IMAGE BLOCK -->
						<div class="col-md-6 order-first order-md-2">
							<div class="img-block right-column wow fadeInLeft">
								<img class="img-fluid" src="@/assets/images/devtools.png" alt="content-image">
							</div>
						</div>


					</div>	<!-- END SECTION CONTENT (ROW) -->	


				</div>	   <!-- End container -->
			</section>	<!-- END TEXT CONTENT -->




			<!-- show all project -->
			<!-- FEATURES-2
			============================================= -->
			<section id="our-project" class="py-100 projects-section division">
				<div class="container">


					<!-- SECTION TITLE -->	
					<div class="row justify-content-center">	
						<div class="col-md-10 col-lg-9">
							<div class="section-title mb-80">	

								<!-- Title -->	
								<h2 class="s-50 w-700">Nos Réalisations !</h2>	

								<!-- Text -->	
								<p class="s-21 color--grey">Take a glance at our projects.</p>
									
							</div>	
						</div>
					</div>

					<div class="row elements">

						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="https://autodznews.com/fr" target="_blanck">
									<img src="@/assets/images/new/autodznews.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>Autodznews</h4>
							<p class="">website responsive for mobile, Backend with Laravel.</p>
						</div>
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="https://batterie-cbs.com/" target="_blanck">
									<img src="@/assets/images/new/cbs.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>CBS</h4>
							<p>website vitrine, Backend with Laravel</p>
						</div>
						
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="#" target="_blanck">
									<img src="@/assets/images/new/quota2.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>Quota</h4>
							<p>Mobile application, for stock management of the pharmacies</p>
						</div>
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="https://play.google.com/store/search?q=tahwissa&c=apps&hl=fr&gl=US" target="_blanck">
									<img src="@/assets/images/new/tahwissa.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>Tahwissa</h4>
							<p>Mobile application, to find amazing places in Algeria</p>
						</div>
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="https://play.google.com/store/apps/details?id=com.hadje.hadj_sayeh_bijoux&hl=en_ZA&gl=US" target="_blanck">
									<img src="@/assets/images/new/sayeh.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>El Hadje sayah</h4>An application
							<p>Mobile application, to browse products for gold shop owners</p>
						</div>
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="http://grazia-demo.badnitech.com/" target="_blanck">
									<img src="@/assets/images/new/grazia.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>Grazia</h4>
							<p>Ecommerce website with worpdress. cosmetic products</p>
						</div>
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="http://grazia-demo.badnitech.com/" target="_blanck">
									<img src="@/assets/images/new/bermi.webp" class="img-fluid" alt="">
								</a>
							</div>
							<h4>Bermi</h4>
							<p>website vitrine, Backend with Laravel.</p>
						</div>
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="https://play.google.com/store/apps/details?id=com.sadeeminfo.operateur_app&hl=en_ZA&gl=US" target="_blanck">
									<img src="@/assets/images/new/nf.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>NF Logistique</h4>
							<p>Mobile application, stock management</p>
						</div>
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="https://play.google.com/store/apps/details?id=com.sadeeminfo.el_yanabie" target="_blanck">
									<img src="@/assets/images/new/hanouti.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>Hannouti</h4>
							<p>Mobile application, to browse products for grocery store owners</p>
						</div>
						<!-- <div class="col-md-4">
							<div class="hover-overlay">
								<a href="https://play.google.com/store/apps/details?id=com.doctidoxxo.doctidox&hl=fr_CH&gl=US" target="_blanck">
									<img src="@/assets/images/new/quota2.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>DOCTIDOX</h4>
							<p>Mobile application, and backend with Laravel</p>
						</div>
						<div class="col-md-4">
							<div class="hover-overlay">
								<a href="#" target="_blanck">
									<img src="@/assets/images/new/quota2.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>POULETVERT</h4>
							<p>Mobile application, and backend with Laravel</p>
						</div> -->
						<!-- <div class="col-md-4">
							<div class="hover-overlay">
								<a href="#" target="_blanck">
									<img src="@/assets/images/new/autodznews.jpg" class="img-fluid" alt="">
								</a>
							</div>
							<h4>Autodznews</h4>
						</div> -->

					</div>
				</div>
			</section>




			<!-- INTEGRATIONS-2
			============================================= -->
			<section id="integrations-2" class="pt-100 integrations-section d-none">
				<div class="container">


					<!-- INTEGRATIONS-2 WRAPPER -->
					<div class="integrations-2-wrapper bg--02 r-12 text-center">


						<!-- SECTION TITLE -->	
						<div class="row justify-content-center">	
							<div class="col-md-10 col-lg-9">
								<div class="section-title mb-60">	

									<!-- Title -->	
									<h2 class="s-50 w-700">Easy integrate all your essential tools</h2>

									<!-- Text -->	
									<p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>
										
								</div>	
							</div>
						</div>

						
						<!-- TOOLS ROW -->	
						<div class="row row-cols-1 row-cols-sm-3 row-cols-md-5">


							<!-- TOOL #1 -->
		 					<div class="col">
		 						<a href="#" class="in_tool it-1 r-12 wow fadeInUp">

		 							<!-- Logo -->
									<div class="in_tool_logo ico-65 bg--white-100 block-shadow r-12">
										<img class="img-fluid" src="@/assets/images/png_icons/tool-1.png" alt="brand-logo">
									</div>

									<!-- Title -->
									<h6 class="s-17 w-700">Zapier</h6>

		 						</a>
		 					</div>	<!-- END TOOL #1 -->	


		 					<!-- TOOL #2 -->
		 					<div class="col">
		 						<a href="#" class="in_tool it-2 r-12 wow fadeInUp">

									<!-- Logo -->
									<div class="in_tool_logo ico-65 bg--white-100 block-shadow r-12">
										<img class="img-fluid" src="@/assets/images/png_icons/tool-2.png" alt="brand-logo">
									</div>

									<!-- Title -->
									<h6 class="s-17 w-700">Google Analytics</h6>

		 						</a>
		 					</div>	<!-- END TOOL #2 -->	


		 					<!-- TOOL #3 -->
		 					<div class="col">
		 						<a href="#" class="in_tool it-3 r-12 wow fadeInUp">

		 							<!-- Logo -->
									<div class="in_tool_logo ico-65 bg--white-100 block-shadow r-12">
										<img class="img-fluid" src="@/assets/images/png_icons/tool-3.png" alt="brand-logo">
									</div>

									<!-- Title -->
									<h6 class="s-17 w-700">Amplitude</h6>

		 						</a>
		 					</div>	<!-- END TOOL #3 -->	


		 					<!-- TOOL #4 -->
		 					<div class="col">
		 						<a href="#" class="in_tool it-4 r-12 wow fadeInUp">

		 							<!-- Logo -->
									<div class="in_tool_logo ico-65 bg--white-100 block-shadow r-12">
										<img class="img-fluid" src="@/assets/images/png_icons/tool-4.png" alt="brand-logo">
									</div>

									<!-- Title -->
									<h6 class="s-17 w-700">Hubspot</h6>

		 						</a>
		 					</div>	<!-- END TOOL #4 -->	


		 					<!-- TOOL #5 -->
		 					<div class="col">
		 						<a href="#" class="in_tool it-5 r-12 wow fadeInUp">

		 							<!-- Logo -->
									<div class="in_tool_logo ico-65 bg--white-100 block-shadow r-12">
										<img class="img-fluid" src="@/assets/images/png_icons/tool-5.png" alt="brand-logo">
									</div>

									<!-- Title -->
									<h6 class="s-17 w-700">MailChimp</h6>

		 						</a>
		 					</div>	<!-- END TOOL #5 -->	


						</div>	<!-- END TOOLS ROW -->	  

						<!-- MORE BUTTON -->
						<div class="row">
							<div class="col">
								<div class="more-btn text-center mt-60 wow fadeInUp">	
									<a href="integrations.html" class="btn btn--tra-grey hover--theme">View all integrations</a>
								</div>	
							</div>
						</div>


					</div>	<!-- END INTEGRATIONS-2 WRAPPER -->


				</div>     <!-- End container -->
			</section>	<!-- END INTEGRATIONS-2 -->


			<!-- Companies
			============================================= -->
			<div id="brands-1" class="py-100 brands-section d-none">
				<div class="container">	

					<!-- BRANDS TITLE -->
					<div class="row justify-content-center">	
						<div class="col-md-10 col-lg-9">
							<div class="brands-title mb-50">
								<h5 class="s-20 w-700">Trusted and used by over 3,400 companies</h5>
							</div>
						</div>
					</div>

					<!-- BRANDS CAROUSEL -->				
					<div class="row">
						<div class="col text-center">	
							<div class="owl-carousel brands-carousel-6">

												
								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-1.png" alt="brand-logo"></a>
								</div>

													
								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-2.png" alt="brand-logo"></a>
								</div>

													
								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-3.png" alt="brand-logo"></a>
								</div>

													
								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-4.png" alt="brand-logo"></a>
								</div>

													
								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-5.png" alt="brand-logo"></a>
								</div>

													
								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-6.png" alt="brand-logo"></a>
								</div>


								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-7.png" alt="brand-logo"></a>
								</div>

															
								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-8.png" alt="brand-logo"></a>
								</div>

								
								<!-- BRAND LOGO IMAGE -->
								<div class="brand-logo">
									<a href="#"><img class="img-fluid" src="@/assets/images/brand-9.png" alt="brand-logo"></a>
								</div>


							</div>	
						</div>
					</div>  <!-- END BRANDS CAROUSEL -->

				</div>	<!-- End container -->
			</div>	<!-- END BRANDS-1 -->




			<!-- DIVIDER LINE -->
			<hr class="divider">




			<!-- FAQs-3
			============================================= -->
			<section id="faq" class="pt-100 faqs-section">				
				<div class="container">


					<!-- SECTION TITLE -->	
					<div class="row justify-content-center">	
						<div class="col-md-10 col-lg-9">
							<div class="section-title mb-70">	

								<!-- Title -->	
								<h2 class="s-50 w-700">Questions & Answers</h2>	

								<!-- Text -->	
								<p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>
									
							</div>	
						</div>
					</div>


					<!-- FAQs-3 QUESTIONS -->	
					<div class="faqs-3-questions">
						<div class="row">


							<!-- IMAGE BLOCK -->
						<div class="col-md-5">
							<div class="img-block left-column wow fadeInRight">
								<img class="img-fluid" src="@/assets/images/qa.png" alt="content-image">
							</div>
						</div>


							<!-- QUESTIONS WRAPPER -->
							<div class="col-lg-6">
								<div class="questions-holder">

								
									<!-- QUESTION #4 -->					
									<div class="question mb-35 wow fadeInUp">

										<!-- Question -->
										<h5 class="s-22 w-700">What services do you offer?</h5>

										<!-- Answer -->
										<p class="color--grey">We specialize in web and mobile app development, WordPress solutions, payment integration, web hosting, SEO, design, and project consultancy.</p>

									</div>

										
									<!-- QUESTION #5 -->
									<div class="question mb-35 wow fadeInUp">

										<!-- Question -->
										<h5 class="s-22 w-700">Can you customize a solution to fit my business needs?</h5>

										<!-- Answer -->
										<p class="color--grey">Yes, we provide tailored solutions designed specifically to meet your business objectives and requirements.</p>

									</div>


									<!-- QUESTION #6 -->
									<div class="question mb-35 wow fadeInUp">

										<!-- Question -->
										<h5 class="s-22 w-700">Do you provide ongoing support after project completion?</h5>

										<!-- Answer -->
										<p class="color--grey">Absolutely! We offer reliable post-launch support, maintenance, and updates to ensure your project continues to perform optimally.</p>

									</div>


								</div>
							</div>	<!-- END QUESTIONS HOLDER -->


						</div>  <!-- End row -->	
					</div>	<!-- END FAQs-3 QUESTIONS -->	


					<!-- MORE QUESTIONS LINK -->	
					<div class="row">
						<div class="col">	
							<div class="more-questions mt-40">
								<div class="more-questions-txt bg--white-400 r-100">
									<p class="p-lg">Have any questions? 
										<a href="contacts.html" class="color--theme">Contact Us</a>
									</p>
								</div>
							</div>
						</div>
					</div>


				</div>	   <!-- End container -->		
			</section>	<!-- END FAQs-3 -->





			<!-- BANNER-13
			============================================= -->
			<section id="contact" class="pt-100 banner-section">
				<div class="container">


					<!-- BANNER-13 WRAPPER -->
					<div class="banner-13-wrapper bg--05 bg--scroll r-16 block-shadow">
						<div class="banner-overlay">
							<div class="row d-flex align-items-center p-4">


								<!-- BANNER-5 TEXT -->
								<div class="col-md-7">
									<div class="banner-13-txt color--white">

										<!-- Title -->	
										<h2 class="s-45 w-700">Are You Ready to Kick-Start Your Project Outline?</h2>

										<!-- Text -->
										<p class="p-lg">Don’t Wait and Build a robust strategy on design, development, or branding ideas for your company with Celestial.</p>

										<!-- Button -->
										<router-link to="/contact" class="btn r-04 btn--theme hover--tra-white" data-bs-toggle="modal" data-bs-target="#modal-3">Contact Us</router-link>

									</div>
								</div>	<!-- END BANNER-13 TEXT -->


								<!-- BANNER-13 IMAGE -->
								<div class="col-md-5">
									<div class="banner-10-img text-center">
										<img class="img-fluid" src="@/assets/images/new/contact.png" alt="banner-image">
									</div>	
								</div>


							</div>   <!-- End row -->	
						</div>   <!-- End banner overlay -->	
					</div>    <!-- END BANNER-13 WRAPPER -->


				</div>     <!-- End container -->	
			</section>	<!-- END BANNER-13 -->




			<!-- DIVIDER LINE -->
			<hr class="divider">




			<!-- MODAL WINDOW (IMAGE LINK)
			============================================= -->
			<div id="modal-1" class="modal auto-off fade d-none" tabindex="-1">
			    <div class="modal-dialog modal-dialog-centered">
			        <div class="modal-content">
			            <!-- CLOSE BUTTON -->
			            <button type="button" class="btn-close ico-10 white-color" data-bs-dismiss="modal" aria-label="Close">
			            	<span class="flaticon-cancel"></span>
			            </button>
			            <!-- MODAL CONTENT -->
	                    <div class="bg-img rounded">
                            <div class="overlay-light">
                                <div class="modal-img text-center">
                                	<a href="pricing-1.html">
                                		<img class="img-fluid" src="@/assets/images/modal-2-img.jpg" alt="modal-image">
                                	</a>
                                </div>
                            </div>
	                    </div>	<!-- END MODAL CONTENT -->
			        </div>
			    </div>
			</div>	<!-- END MODAL WINDOW (IMAGE LINK) -->


			<!-- MODAL WINDOW (REQUEST FORM)
			============================================= -->
			<div id="modal-3" class="modal auto-off fade d-none" tabindex="-1" role="dialog" aria-labelledby="modal-3">
				 <div class="modal-dialog modal-xl modal-dialog-centered">
			        <div class="modal-content">


			        	<!-- CLOSE BUTTON -->
			            <button type="button" class="btn-close ico-10 white-color" data-bs-dismiss="modal" aria-label="Close">
			            	<span class="flaticon-cancel"></span>
			            </button>


			            <!-- MODAL CONTENT -->
			            <div class="modal-body">
			            	<div class="container">
                  				 <div class="row">


                  				 	<!-- BACKGROUND IMAGE -->
                  				 	<div class="col-md-5 bg-img d-none d-sm-flex align-items-end"></div>


                  				 	<!-- REQUEST FORM -->
                  				 	<div class="col-md-7">
                  				 		<div class="modal-body-content">

                  				 			<!-- TEXT -->
                  				 			<div class="request-form-title">

		                  				 		<!-- Title 	-->	
												<h3 class="s-28 w-700">Get started for Free!</h3>	

												<!-- Text -->
												<p class="color--grey">Aliquam augue suscipit, luctus neque purus ipsum 
												   neque dolor primis libero
												</p>

											</div>
	                  				 		
											<!-- FORM -->	
	                  				 		<form name="requestForm" class="row request-form">
							                                            
								                <!-- Form Input -->
								                <div class="col-md-12">
								                	<input type="text" name="name" class="form-control name" placeholder="Enter Your Name*" autocomplete="off" required> 
								                </div>
								                     
								                <!-- Form Input -->       
								                <div class="col-md-12">
								                	<input type="email" name="email" class="form-control email" placeholder="Enter Your Email*" autocomplete="off" required> 
								                </div>

								                <!-- Form Button -->
								                <div class="col-md-12 form-btn">  
								                	<button type="submit" class="btn btn--theme hover--black submit">Get Started Now</button>
								                </div>
								                                                              
								                <!-- Form Message -->
								                <div class="col-md-12 request-form-msg">
								                	<span class="loading"></span>
								                </div>   
								                                              
								            </form>	<!-- END FORM -->		

								        </div>
                  				 	</div>	<!-- END REQUEST FORM -->
		            		

                  				 </div>
                  			</div>
			            </div>	<!-- END MODAL CONTENT -->


					 </div>
			    </div>
			</div>	<!-- END MODAL WINDOW (REQUEST FORM) -->

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {

  }
}
</script>
